/* eslint-disable react/require-default-props */
import { useOutputContext } from 'hooks/useGlobalContext'
import PropTypes from 'prop-types'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = ({ loginPath = '/login' }) => {
  const { loggedIn } = useOutputContext()

  return loggedIn ? <Outlet /> : <Navigate to={loginPath} replace />
}

PrivateRoute.propTypes = {
  loginPath: PropTypes.string,
}

export default PrivateRoute
