import React, { createContext } from 'react'

const defaultValue = {
  loggedIn: false,
  loggedInUser: undefined,
  userRole: undefined,
  setLoggedIn: () => {},
  setLoggedInUser: () => {},
  setUserRole: () => {},
  logOut: () => {},
}

export const GlobalContext = createContext(defaultValue)

export const Provider = ({ children, value }) => {
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}
