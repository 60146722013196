import { Box, CircularProgress } from '@mui/material'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import document from 'global/document'
import 'index.css'
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Provider } from 'store/Provider'

const AsyncLoginPage = lazy(() => import('components/Login'))
const AsyncAppPage = lazy(() => import('App'))

const LoadingSpinner = () => (
  <Box sx={{ display: 'flex' }}>
    <CircularProgress />
  </Box>
)

const Root = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState(undefined)
  const [userRole, setUserRole] = useState(undefined)

  useEffect(() => {
    const token = localStorage.getItem('registerInfo')
    setLoggedIn(!!token)
  }, [])

  const logOut = () => {
    localStorage.removeItem('registerInfo')
    setLoggedIn(false)
    setUserRole(undefined)
    setLoggedInUser(undefined)
  }

  const ctx = useMemo(
    () => ({
      loggedIn,
      loggedInUser,
      userRole,
      setLoggedIn,
      setLoggedInUser,
      setUserRole,
      logOut,
    }),
    [loggedIn, loggedInUser, setLoggedIn, setLoggedInUser, setUserRole, userRole],
  )

  return (
    <React.StrictMode>
      <Provider value={ctx}>
        <BrowserRouter>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route exact path='/login' element={<AsyncLoginPage />} />
              <Route exact path='/' element={<PrivateRoute />}>
                <Route path='/' element={<AsyncAppPage />} />
              </Route>
              <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  )
}

render(<Root />, document.getElementById('root'))
